<template>
    <div class="navbar-fixed-bottom">
        Footer
    </div>
</template>

<script>

export default {
    name: "Footer",
}
</script>
