

export const DEFAULT_CANVAS_OPTIONS = {
    fillStyle: '#D3D3D3',
    lineWidth: 1,
    strokeStyle: '#505050',
    mashingCoefficient: 0.1,
};


export const DEFAULT_OSCILLOGRAM_OPTIONS = {
    startDeg: 0,
    radiusCoefficient: 0.234,
    marginCoefficient: 0.5,
    freqShift: 90,
    oscCoefficient: 6,
    roundStep: 0.065,
    lastPixelsPart: 1,
    roundDeg: 6.28,
    speedWavesDrawing: 18,
    loadingPosition: 0,
};