import {AVAILABLE_LANGUAGES} from "@/resources/js/static/constants";
import Lang from "@/resources/js/components/Lang";


export default {
    name: "LanguageSwitcher",
    mixins: [Lang],
    data() {
        return {
        }
    },
    methods: {
        langsWithoutCurrent() {
            return Object.values(AVAILABLE_LANGUAGES).filter((language) => {
                return language.code !== this.currentLang.code;
            });
        }
    },
}