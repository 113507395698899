<template>
    <div id="app">
        <main-header></main-header>
        <component :is="layout">
            <router-view/>
        </component>
        <main-footer></main-footer>
    </div>
</template>

<script>
import Header from "@/resources/views/main-elements/Header";
import Footer from "@/resources/views/main-elements/Footer";
import PageMeta from "@/resources/js/components/PageMeta";

export default {
    computed: {
        layout() {
            return this.$route.meta.layout || 'default-layout'
        }
    },
    components: {
        'main-header': Header,
        'main-footer': Footer,
    },
    mixins: [
        PageMeta,
    ],
}
</script>
<style src="@/resources/css/main/default.css"></style>
<style src="@/resources/css/main/screen_max_650.css"></style>
<style src="@/resources/css/main/screen_min_651.css"></style>

