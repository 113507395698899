import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueAxios from 'vue-axios'
import axios from 'axios';
import DefaultLayout from './resources/views/layouts/DefaultLayout.vue';


Vue.component('default-layout', DefaultLayout);

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

const token = localStorage.getItem('token')
if (token) {
    store.state.user.loggedIn = true;
    Vue.axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}

Vue.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

new Vue({
    router,
    store,
    VueAxios,
    render: h => h(App),
    methods: {
    },
}).$mount('#app')
