


export default {
    name: "TrackInfo",

    data() {
        return {
            album: '',
            title: '',
            artist: '',
            id: '',
            // streamMeta: {},
        }
    },
    // created() {
    //     // this.getStreamMeta();
    // },

    watch: {
        '$parent.streamMeta': function(streamMeta) {
            this.album = streamMeta.album;
            this.title = streamMeta.title;
            this.artist = streamMeta.artist;
            this.id = streamMeta.id;
        },
        // '$parent.silence': function (silence) {
        //     if (silence) {
        //         setTimeout(() => {
        //             if (this.$parent.silence) {
        //                 this.getStreamMeta();
        //             }
        //         }, 350);
        //     }
        // },
    },

    computed: {
        trackPic() {
            if (!this.id) {
                return;
            }
            return require(`@/assets/images/tracks/${this.id}.jpg`);
        }
    },

    methods: {

        // getStreamMeta() {
        //     this.axios.get('/stream-meta')
        //         .then(response => {
        //             this.streamMeta = response.data;
        //         }).catch(error => {
        //         console.log(error);
        //     });
        // },
    },
}
