<template>
    <div class="container" id="page">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "DefaultLayout",
}
</script>
