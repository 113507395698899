import Vue from 'vue'
import Vuex from 'vuex'
import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE} from "@/resources/js/static/constants";
import { getLangs } from "@/resources/js/components/Lang";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        layout: 'default-layout',
        token: localStorage.getItem('token') || null,
        currentLanguage: AVAILABLE_LANGUAGES[localStorage.getItem('lang_code')] || DEFAULT_LANGUAGE,
        langMessages: {},
        langsLoaded: false,
        user: {
            loggedIn: false,
            isSubscribed: true,
        }
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload
        },
        setLangsLoaded(state, payload) {
            state.langsLoaded = payload;
        },
        setLangMessages(state, lang) {
            if (lang.code !== state.currentLanguage.code && (Object.values(AVAILABLE_LANGUAGES).find((language) => {
                return language.code === lang.code;
            })) || !(state.langMessages.length > 0)) {
                state.currentLanguage = lang;
                state.langsLoaded = false;
                getLangs({params: {lang: state.currentLanguage.code}})
                    .then(response => {
                        state.langMessages = response
                        state.langsLoaded = true;
                    }).catch(error => console.log(error));
            }
        },
        setCurrentLang(state, payload) {
            state.currentLanguage = payload;
        },
        logout(state){
            state.user.loggedIn = false;
            state.token = null;
        },
        login(state, token){
            state.user.loggedIn = true;
            state.token = token;
        },
    },
    actions: {
        logout({commit}){
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token');
                delete Vue.axios.defaults.headers.common['Authorization'];
                resolve()
            })
        }
    },
    modules: {},
    getters: {
        layout(state) {
            return state.layout
        },
        auth(state) {
            return state.user
        },
        getMessages(state) {
            return state.langMessages;
        }
    }
})
