// import router from "@/router";
// import store from "@/store";


export const commonFunctions = {
    methods: {
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push({path: `/${this.$route.params.lang || ''}`}).catch((err) => {
                        console.log(err);
                    });
                })
        },
        isLogged: function () {
            return this.$store.getters.auth.loggedIn;
        },
        isSubscriber: function () {
            return this.$store.getters.auth.isSubscribed;
        }
    }
}