import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=31c0e9f2&"
import script from "@/resources/js/main-elements/header?vue&type=script&lang=js&"
export * from "@/resources/js/main-elements/header?vue&type=script&lang=js&"
import style0 from "@/resources/css/main/header.css?vue&type=style&index=0&media=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports