import {DEFAULT_CANVAS_OPTIONS, DEFAULT_OSCILLOGRAM_OPTIONS} from "@/resources/js/components/Player/settings";


export default {

    data() {
        return {
            drawCurveIntervalId: [],
            context: null,
            radius: null,
            margin: null,

            ...DEFAULT_OSCILLOGRAM_OPTIONS,
            ...DEFAULT_CANVAS_OPTIONS,
        };
    },



    watch: {
        frequencyData:  {
            handler: function() {
                this.drawWaves();
            },
        },
    },

    methods: {

        renewArea(alpha = 1, smooth = 1, renewCircle = false) {
            this.context.fillStyle = this.fillStyle;
            this.context.globalAlpha = alpha;
            for (let i = 0; i <= smooth; i++) {
                if (renewCircle) {
                    setTimeout(() => {
                        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
                        this.drawWaves();
                    }, 10);
                } else {
                    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
                }
            }
        },

        drawSmoothPause() {
            let intervalId = this.getRandomInt(100);
            this.drawCurveIntervalId[intervalId] = setInterval(() => {
                this.frequencyData.forEach((byte) => {
                    return (byte > 0) ? (byte / 1.2) : 0;
                });
                if (this.freqSum === 0) {
                    this.dropIntervals();
                    this.renewArea(0.05, 100, true);
                    this.drawWaves();
                }

            }, this.speedWavesDrawing);

        },

        drawLoading()
        {
            this.context.fillStyle = this.strokeStyle;
            this.context.globalAlpha = 1;
            this.context.strokeStyle = this.strokeStyle;
            let x = (this.radius * Math.cos(this.loadingPosition)) + this.margin;
            let y = (this.radius * Math.sin(this.loadingPosition)) + this.margin;
            this.loadingPosition += 0.03;
            this.context.beginPath();
            this.context.arc(x, y, 2, 0, 2*Math.PI, true);
            this.context.fill();
            this.context.stroke();
            this.context.fillStyle = this.fillStyle;
            this.context.globalAlpha = 0.03;
            this.context.fillRect (0, 0, this.canvas.width, this.canvas.height);
        },
        
        drawWaves(mashingCoefficient){
            this.context.lineWidth = this.lineWidth;
            let freqShift = this.freqShift;
            let osc = Math.round(this.frequencyData[freqShift] / this.oscCoefficient);

            let startX = ((this.radius + osc) * Math.cos(this.startDeg)) + this.margin;
            let startY = ((this.radius + osc) * Math.sin(this.startDeg)) + this.margin;

            this.context.fillStyle = this.fillStyle;
            this.context.globalAlpha = mashingCoefficient || this.mashingCoefficient;
            this.context.fillRect (0, 0, this.canvas.width, this.canvas.height);
            this.context.beginPath();
            this.context.strokeStyle = this.strokeStyle;
            this.context.globalAlpha = 1;
            this.context.moveTo(startX, startY);
            let roundDeg = this.roundDeg;
            let roundDegShift = this.roundStep * this.lastPixelsPart;

            for (let i = roundDeg; i >= roundDegShift; i -= this.roundStep){
                osc = this.frequencyData[freqShift++] / this.oscCoefficient;
                let x = ((this.radius + osc) * Math.cos(i)) + this.margin;
                let y = ((this.radius + osc) * Math.sin(i)) + this.margin;
                this.context.lineTo(x, y);
            }

            this.context.lineTo(startX, startY);
            this.context.stroke();
        },
        dropIntervals() {
            this.drawCurveIntervalId.forEach(interval => {
                clearInterval(interval);
            });
        },
        getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        },
        initCanvas(options = {}) {
            this.canvas = this.$refs.player_button.$refs.canvas;
            this.canvas.fillStyle       = options.fillStyle || DEFAULT_CANVAS_OPTIONS.fillStyle;
            this.canvas.lineWidth       = options.lineWidth || DEFAULT_CANVAS_OPTIONS.lineWidth;
            this.canvas.strokeStyle     = options.strokeStyle || DEFAULT_CANVAS_OPTIONS.strokeStyle;
        },
        initOscillogram(options = {}) {
            this.context           = this.canvas.getContext('2d');
            this.radius            = options.radius || (this.canvas.width * DEFAULT_OSCILLOGRAM_OPTIONS.radiusCoefficient);
            this.margin            = options.margin || (this.canvas.width * DEFAULT_OSCILLOGRAM_OPTIONS.marginCoefficient);
            Object.entries(options).forEach(([key, value]) => {
                this[key] = value;
            });
        },
    },
    mounted() {
        this.initCanvas();
        this.initOscillogram();
        let intervalId = this.getRandomInt(100);
        this.drawCurveIntervalId[intervalId] = setInterval(() => this.drawLoading(), 5);
    }
}