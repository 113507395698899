import HeaderMenu from "@/resources/views/menu/HeaderMenu";
import PlayerButton from "@/resources/views/main-elements/player/PlayerButton";
import TrackInfo from "@/resources/views/main-elements/player/TrackInfo";
import Stream from "@/resources/js/components/Player/Stream";
import Painter from "@/resources/js/components/Player/Painter";

export default {
    components: {
        HeaderMenu,
        PlayerButton,
        TrackInfo,
    },
    mixins: [Stream, Painter],
    name: "Player",

    data() {
        return {
            playing: false,
            canPlay: false,
            audio: null,
            canvas: null,
        }
    },
    watch: {
        playing: function (play) {
            if (play) {
                this.audioCtx.resume();
                this.audio.play();
            } else {
                this.audio.pause();
                this.drawSmoothPause();
            }
        },

    },

    methods: {
        toggleButton(isPlaying) {
            this.playing = isPlaying;
        }
    },

    created() {
        this.$root.$refs.player = this;
        this.getStreamMeta();
        this.audio = new Audio(`http://jazzforsoul.org:8888/stream`);
        this.audio.setAttribute("crossorigin", "anonymous");
        // this.audio.load();

        this.audio.oncanplay = () => {
            this.audio.pause();
            this.canPlay = true;
            this.dropIntervals();
            this.renewArea()
            this.drawWaves();
        };
        this.audio.onplay = () => {
            this.playing = true;

            let intervalId = this.getRandomInt(100);
            this.drawCurveIntervalId[intervalId] = setInterval(() => {
                let frequencyData =  new Uint8Array(this.analyzer.frequencyBinCount);
                this.analyzer.getByteFrequencyData(frequencyData);
                this.frequencyData = frequencyData;
            }, this.speedWavesDrawing)
        };
        this.audio.onplaying = () => {
            this.playing = true;
            // this.handleByteFrequencyData();

            let intervalId = this.getRandomInt(100);
            this.drawCurveIntervalId[intervalId] = setInterval(() => {
                this.analyzer.getByteFrequencyData(this.frequencyData);
            }, this.speedWavesDrawing)
        };
        this.audio.onend = () => {
            this.playing = false;
        };
        this.audio.onabort = () => {
            this.playing = false;
            this.dropIntervals();
        };
        this.audio.onerror = (error) => {
            this.playing = false;
            console.log(error);
        };
        this.audio.oninterruptbegin = () => {
            this.playing = false;
        };

        this.audio.onpause = () => {
            this.playing = false;
            this.drawSmoothPause();
        };

    },
}