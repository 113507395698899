import Lang from "@/resources/js/components/Lang";


export default {
    mixins: [Lang],
    name: "PageMeta",

    watch: {
        '$store.state.langsLoaded': function (loaded) {
            if (loaded && this.$route.name) {
                this.fetchMeta();
            }
        },
        '$route': function () {
            if (this.$store.state.langsLoaded) {
                this.fetchMeta();
            }
        },
    },

    methods: {
        fetchMeta() {
            document.title = this.trans(this.$route.name + '.meta.title');
            this.setMeta('description', this.trans(this.$route.name + '.meta.description'))
        },
        setMeta(metaName, content) {
            let meta = document.head.getElementsByTagName('meta').namedItem(metaName);
            if (!meta) {
                meta = document.createElement('meta');
                let beforeElement = document.head.getElementsByTagName('link')[0];
                document.head.insertBefore(meta, beforeElement);
            }
            meta.name = metaName;
            meta.content = content;
        }
    },
}