import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE} from "@/resources/js/static/constants";
import axios from "axios";

const Lang = {

    methods: {
        getLangPath(lang) {
            return getLangPath(lang);
        },
        getUrlWithoutLang(url) {
            return getUrlWithoutLang(url);
        },
        getUrlWithCurrentLang(url) {
            return this.trimSlashes(this.getLangPath(this.currentLang) + url);
        },
        getUrlWithNewLang(lang, url) {
            return this.trimSlashes(this.getLangPath(lang) + this.getUrlWithoutLang(url))
        },
        trimSlashes(url) {
            url = url.replace(/\/$/g, '');
            return (url.length > 0) ? url : '/';
        },
        setLanguageMessages(lang) {
            this.$store.commit('setLangMessages', lang)
        },
        trans(key = '') {
            let explodedKey = key.split('.');
            let result;
            if ((result = this.lang[explodedKey[0]]) === undefined) {
                return key;
            }
            for (let i = 1; i < explodedKey.length; i++) {
                if ((result = result[explodedKey[i]]) === undefined) {
                    return key;
                }
            }
            return (typeof result === "string") ? result : key ;
        }

    },

    computed: {
        currentLang() {
            return Object.values(AVAILABLE_LANGUAGES).find((language) => {
                return language.code === this.$route.params.lang;
            }) || DEFAULT_LANGUAGE;
        },

        lang() {
            return this.$store.getters.getMessages;
        },

    },
};

export const getLangs = async (configs) =>
{
    try {
        const response = await axios.get('/langs', configs);
        return response.data;
    } catch (error) {
        throw new Error(`Something wrong: ${error.toString()} {${JSON.stringify(configs)}}`)
    }
}


export function getLangPath(lang) {
    return (lang.code !== DEFAULT_LANGUAGE.code) ? lang.path : '';
}

export function getUrlWithoutLang(url) {

    let langsString = Object.values(AVAILABLE_LANGUAGES).map((language) => {
        return '\\' + language.path;
    }).join('|');
    let regExp = new RegExp(`^(${langsString})`, 'g');
    return url.replace(regExp, '');
}

export default Lang;