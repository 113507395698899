

export default {
    name: "PlayerButton",
    props: {
        playing: {
            type: Boolean,
            value: false,
        },
    },
    data() {
        return {
            playerButtonClass: 'loading',
        }
    },
    computed: {
        isSubscriber() {
            return this.$store.state.user.isSubscribed;
        },
    },
    watch: {
        '$parent.playing': function (playing) {
            this.playerButtonClass = playing ? 'play' : 'pause';
        },
        '$parent.canPlay': function (can) {
            this.playerButtonClass = can ? 'pause' : 'loading';
        }
    },

    methods: {
        playStop() {
            this.$emit('toggle', !this.playing);
        }
    },

    mounted() {
        // console.log(this.$parent.playing)
    }
}