import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import guest from './middleware/guest'
import auth from './middleware/auth'
// import isSubscribed from './middleware/isSubscribed'
import middlewarePipeline from './middleware/middlewarePipeline'

import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE} from "@/resources/js/static/constants";
import Lang from "@/resources/js/components/Lang";


Vue.use(VueRouter)

const routes = [{
    path: '/:lang(' + Object.values(AVAILABLE_LANGUAGES).map((language) => {
        return language.code
    }).join('|') + ')?',
    component: {
        render(element) {
            return element('router-view');
        },
    },
    children: [
        {
            path: '',
            name: 'home',
            component: () => import('@/resources/views/pages/HomePage'),
        },

        {
            path: 'artists/:alias',
            name: 'artist',
            component: () => import('@/resources/views/pages/ArtistPage'),
        },
        {
            path: 'sign-up',
            name: 'registration',
            component: () => import('@/resources/views/pages/RegistrationPage'),
            meta: {
                middleware: [
                    guest
                ]
            },
        },
        {
            path: 'sign-in',
            name: 'login',
            component: () => import('@/resources/views/pages/LoginPage'),
            meta: {
                middleware: [
                    guest
                ]
            },
        },
        {
            path: 'account',
            name: 'account',
            component: () => import('../resources/views/pages/Account.vue'),
            meta: {
                middleware: [
                    auth,
                    // isSubscribed
                ]
            },
        },
        {
            path: '*',
            component: () => import('../resources/views/errors/PageNotFound'),
        },
    ],
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    mixins: [Lang]
});


router.beforeEach((to, from, next) => {

    setLanguageMessages(to.params.lang, to.name);

    if (!to.meta.middleware) {
        return next()
    }


    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
});

export function setLanguageMessages(langCode)
{
    langCode = langCode || DEFAULT_LANGUAGE.code;
    document.querySelector('html').setAttribute('lang', langCode);
    store.commit('setLangMessages', AVAILABLE_LANGUAGES[langCode]);

}

export default router
