export default {

    data() {
        return {
            audioCtx: null,
            source: null,
            analyzer: null,
            frequencyData: [],
            silence: true,
            streamMeta: {},
        }
    },

    beforeMount() {
        let AudioContext = window.AudioContext || window.webkitAudioContext;
        this.audioCtx = new AudioContext();
        this.source = this.audioCtx.createMediaElementSource(this.audio);
        this.analyzer = this.audioCtx.createAnalyser();

        this.source.connect(this.analyzer);
        this.analyzer.connect(this.audioCtx.destination);
        this.frequencyData = new Uint8Array(this.analyzer.frequencyBinCount);
    },
    computed: {
        freqSum: function () {
            let sum = 0;
            for (let i = 200; i >= 0; i--) {
                sum += this.frequencyData[i];
            }
            return sum;
        },

    },
    watch: {
        frequencyData:  {
            handler: function() {
                this.silence = (this.freqSum === 0);
            },
        },
        silence: function(value) {
            if (value) {
                setTimeout(() => {
                    if (this.silence) {
                        this.getStreamMeta();
                    }
                }, 300);
            }
        }
    },
    methods: {

        getStreamMeta() {
            this.axios.get('/stream-meta')
                .then(response => {
                    this.streamMeta = response.data;
                }).catch(error => {
                console.log(error);
            });
        },
    }
}