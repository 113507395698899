import {commonFunctions} from "@/resources/js/mixins/mixins";
import LanguageSwitcher from "@/resources/views/menu/LanguageSwitcher";
import Lang from "@/resources/js/components/Lang";

export default {
    components: {LanguageSwitcher},
    mixins: [commonFunctions, Lang],
    name: "HeaderMenu",
    data: () => {
        return {
        }
    },
    mounted() {
    },


}