export const BUTTON_PAINTER_OPTIONS = {


// TODO old version
    options: {
        lineWidth: 1,
        strokeStyle: "#505050",
        fillStyle: '#D3D3D3',//"lightgrey",

        startDeg: 0,
        r: 35,
        margin: 75,
        j: 90,
    }
}

export const AVAILABLE_LANGUAGES = {
    en: {
        code: 'en',
        path: '/en',
        name: 'English',
        loc_name: 'English',
    },
    ru: {
        code: 'ru',
        path: '/ru',
        name: 'Russian',
        loc_name: 'Русский',
    },
    fr: {
        code: 'fr',
        path: '/fr',
        name: 'French',
        loc_name: 'Français',
    },
    de: {
        code: 'de',
        path: '/de',
        name: 'German',
        loc_name: 'Deutsche',
    },
    es: {
        code: 'es',
        path: '/es',
        name: 'Spanish',
        loc_name: 'Española',
    },
};

export const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES.en;